import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

/*
 * Splash
*/

document.addEventListener("DOMContentLoaded", function() {
  setTimeout(function() {
    var splashScreen = document.getElementById("splashscreen");
    if (splashScreen) {
      splashScreen.style.display = 'none';
    }
  }, 5000);
});


/*
 * Scroll
*/

import LocomotiveScroll from 'locomotive-scroll';

const scroll = new LocomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
  smooth: true,
  multiplier: .5, // default: 1
  // smoothMobile: true
});

// 内部リンクにカスタムスクロールを設定
document.querySelectorAll('a[data-scroll]').forEach(link => {
    link.addEventListener('click', function(e) {
        e.preventDefault();

        // スクロール先のIDを取得
        const targetId = link.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            // ビュー高さの10%（-10vh）をオフセットとして設定
            const offset = -(window.innerHeight * -0.2); // 例：10vhを指定
            const targetPosition = targetElement.getBoundingClientRect().top + scroll.scroll.instance.scroll.y + offset;

            // スクロールを実行
            scroll.scrollTo(targetPosition);
        }
    });
});
